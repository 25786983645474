<template>
  <information-app-modal
    v-model="model"
    button-title="Continue"
    @close="closeModal"
    icon="hourglass"
  >
    <h1 class="title">{{ $t('BankVerificationPendingModal.ModalTitle').value }}</h1>

    <p>
      {{ $t('BankVerificationPendingModal.ModalText').value }}
    </p>

    <template #footer>
      <AppButton @click="closeModal">
        {{ $t('BankVerificationPendingModal.ButtonGotIt').value }}
      </AppButton>
      <AppButton v-if="isSendMoney" theme="secondary" @click="selectNewPaymentMethod">
        {{ $t('BankVerificationPendingModal.DifferentPaymentMethodButton').value }}
      </AppButton>
    </template>
  </information-app-modal>
</template>

<script>


import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'

import { AppButton, useVModel } from '@oen.web.vue2/ui'
import { useRouter } from '@galileo/composables/useRouter'


import { useSendMoneyStore, useI18nStore } from '@galileo/stores'

export default {
  name: 'BankVerificationPendingModal',
  components: {
    AppButton,
    InformationAppModal,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    isSendMoney: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)
    const router = useRouter()
    const sendMoneyStore = useSendMoneyStore()

    const closeModal = () => {
      router.replace({ name: 'Activity' })
      model.value = false
    }

    const selectNewPaymentMethod = () => {
      sendMoneyStore.setPaymentMethodType('BankTransfer')
      sendMoneyStore.setPaymentMethodAction('BankTransfer')
      sendMoneyStore.isPaymentMethodTypeSelected = false
      router.replace({ name: 'SendMoneyPayment' })
    }

    return {
      model,
      $t,
      closeModal,
      selectNewPaymentMethod,
    }
  },
}
</script>

<style scoped>
p {
  @apply text-base;
}
</style>
